import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import numeral from 'numeral'
import moment from 'moment'
import TableContainer from '@mui/material/TableContainer'
import { IPortStatsTableProps, IExpandableRowProps } from './types'
import {
  headTableStyle,
  headTableGreenStyle,
  headTableTangerineStyle,
  cellBorderRight,
  headTableDarkGreenStyle,
} from '../../../styles/generic-styles'
import { theme } from '../../../styles/mui-theme'

const ExpandableRow: React.FC<IExpandableRowProps> = ({ stat, isHighlighted = false }) => {
  const totalStandardBookings =
    (stat.bookingStandardNow || 0) +
    (stat.bookingStandardLater || 0) +
    (stat.bookingRemoteNow || 0) +
    (stat.bookingRemoteLater || 0) +
    (stat.bookingSelfService || 0)
  const cellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.divider,
      }
    : null

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell component="th" scope="row" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.date ? moment.utc(stat.date).format('MM/DD/YYYY') : ''}
      </TableCell>
      <TableCell component="th" scope="row" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.portName}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.qualifiedAppointments || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.totalOverall || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {numeral(stat.conversion).format('0,0.00%')}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.bookingStandardNow || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.bookingStandardLater || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.bookingRemoteNow || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.bookingRemoteLater || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.bookingSelfService || 'None'}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {totalStandardBookings || 0}
      </TableCell>
      <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
        {stat.bookingSupplemental || 'None'}
      </TableCell>
    </TableRow>
  )
}

const PortStatsTable: React.FC<IPortStatsTableProps> = ({ stats }) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} />
          <TableCell rowSpan={2} />
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Qualified Traffic
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Overall Traffic
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Conversion
          </TableCell>
          <TableCell colSpan={2} sx={{ ...headTableGreenStyle, ...cellBorderRight }} align="center">
            Total Standard
          </TableCell>
          <TableCell colSpan={2} sx={{ ...headTableGreenStyle, ...cellBorderRight }} align="center">
            Remote Bookings
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableGreenStyle, ...cellBorderRight }} align="center">
            Self Service Bookings
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{ ...headTableDarkGreenStyle, ...cellBorderRight }}
            align="center">
            Total
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{ ...headTableTangerineStyle, ...cellBorderRight }}
            align="center">
            Supplemental Bookings
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" sx={headTableGreenStyle}>
            BOOK NOW
          </TableCell>
          <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
            BOOK LATER
          </TableCell>
          <TableCell align="center" sx={headTableGreenStyle}>
            BOOK NOW
          </TableCell>
          <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
            BOOK LATER
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stats.map((stat, index) => (
          <ExpandableRow
            key={`row-${index}`}
            stat={stat}
            isHighlighted={index === stats.length - 1}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

export default PortStatsTable
